<template>
  <div>
    <van-row type="flex" justify="center">
      <van-col class="title" span="22">{{this.title}}</van-col>
    </van-row>
    <van-row class="info" type="flex" justify="space-between">
      <van-col span="22">
        <van-row type="flex" justify="space-between">
          <van-col>作者：{{this.author}}</van-col>
          <van-col>时间：{{this.createtime}}</van-col>
        </van-row>
      </van-col>
    </van-row>
    <van-row class="content-top" type="flex" justify="center">
      <van-col class="content" v-html="this.content" span="22"></van-col>
    </van-row>
    <!-- <div>底部地基</div> -->
    <div class="wrap-bottom"></div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      id: "",
      content: "",
      title: "",
      createtime: "",
      author: "",
    };
  },
  created() {
    this.getdetail();
  },
  methods: {
    getdetail() {
      this.id = this.$route.query.id;
      console.log(this.id);
      this.$api.article.getarticledetails(this.id).then((res) => {
        console.log(res);
        this.content = res.data.data.htmlcontent;
        this.title = res.data.data.title;
        this.author = res.data.data.author;
        this.createtime = res.data.data.createtime;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.title {
  font-size: 22px;
  font-weight: bold;
  padding-top: 0.8rem;
}
.info {
  margin-top: 0.8rem;
  font-size: 16px;
  padding: 0rem 0.8rem 0.8rem 0.8rem;
  color: $text-gray;
}
.content {
  font-size: 18px;
  line-height: 160%;
}
</style>